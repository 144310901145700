import React from 'react'
import PropTypes from 'prop-types'
import Tags from '../tags/Tags'
import Archives from '../archives/Archives'
import PostList from '../postList/PostList'
import CategoryTitle from '../categoryTitle/CategoryTitle'
import ListPaging from '../listPaging/ListPaging'
import Style from './layout.module.scss'

const Layout = ({
  title,
  subTitle,
  page,
  totalPage,
  nextPath,
  oldPath,
  tagItems,
  archiveList,
  postItems,
}) => (
  <>
    <div>
      <CategoryTitle title={title} subTitle={subTitle} />
    </div>
    <div className={Style.layoutContentRoot}>
      <main className={Style.layoutMainContent}>
        <PostList items={postItems} />
        <div className={Style.layoutPaging}>
          <ListPaging
            page={page}
            totalPage={totalPage}
            nextPath={nextPath}
            oldPath={oldPath}
          />
        </div>
      </main>
      <div className={Style.layoutMenu}>
        <div>
          <Tags items={tagItems} />
        </div>
        <div className={Style.layoutArchive}>
          <Archives list={archiveList} />
        </div>
      </div>
    </div>
  </>
)

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  nextPath: PropTypes.string,
  oldPath: PropTypes.string,
  tagItems: PropTypes.array,
  archiveList: PropTypes.array,
  postItems: PropTypes.array,
}

export default Layout
