import React from 'react'
import PropTypes from 'prop-types'
import Item from './Item'
import Style from './postList.module.scss'

const PostList = ({ items }) => (
  <div>
    {items.map(item => (
      <div key={item.slug} className={Style.postList}>
        <Item {...item} />
        <hr className={Style.postListDivider} />
      </div>
    ))}
  </div>
)

PostList.propTypes = {
  items: PropTypes.array.isRequired,
}

export default PostList
