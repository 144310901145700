import React from 'react'
import PropTypes from 'prop-types'
import Style from './categoryTitle.module.scss'

const CategoryTitle = ({ title, subTitle }) => (
  <div className={Style.categoryTitleContent}>
    <h1 className={Style.categoryTitleTitle}>{title}</h1>
    <p className={Style.categoryTitleSubTitle}>{subTitle}</p>
  </div>
)

CategoryTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
}

export default CategoryTitle
