import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import ItemStyle from './item.module.scss'
import RelatedTags from '../relatedTags/RelatedTags'
import PostDate from '../postDate'

const Item = ({ slug, imgObj, title, date, description, tags }) => (
  <article className={ItemStyle.postListItem}>
    <div>
      <Img
        className={ItemStyle.postListItemImg}
        fluid={imgObj}
        imgStyle={{ objectFit: 'contain' }}
      />
    </div>
    <div className={ItemStyle.postListItemContent}>
      <Link to={slug} className={ItemStyle.postListItemLink}>
        <section>
          <h2>{title}</h2>
          <p>{description}</p>
        </section>
      </Link>
      <div className={ItemStyle.postListItemTags}>
        <RelatedTags tags={tags} />
      </div>
      <div className={ItemStyle.postListItemDate}>
        <PostDate text={date} />
      </div>
    </div>
  </article>
)

Item.propTypes = {
  slug: PropTypes.string.isRequired,
  imgObj: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
}

export default Item
