import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Style from './listPaging.module.scss'
import LeftIcon from '../../img/icon-left.svg'

const ListPaging = ({ page, totalPage, nextPath, oldPath }) => {
  const newerLink = nextPath ? '' : Style.hidden
  const olderLink = oldPath ? '' : Style.hidden
  return (
    <div className={Style.listPagingRoot}>
      <div className={newerLink}>
        <Link className={Style.linkButton} to={nextPath ?? '/'}>
          <img src={LeftIcon} alt="Left icon" />
          <p className={Style.newerPost}>Newer Posts</p>
        </Link>
      </div>
      <div className={Style.pageContent}>
        <span>Page</span>
        <span className={Style.pageNumber}>{page}</span>
        <span>Of</span>
        <span className={Style.totalPageNumber}>{totalPage}</span>
      </div>
      <div className={olderLink}>
        <Link className={Style.linkButton} to={oldPath ?? '/'}>
          <p className={Style.olderPost}>Older Posts</p>
          <img src={LeftIcon} alt="Right icon" className={Style.rightIcon} />
        </Link>
      </div>
    </div>
  )
}

ListPaging.propTypes = {
  page: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  nextPath: PropTypes.string,
  oldPath: PropTypes.string,
}

export default ListPaging
